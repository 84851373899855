import React from "react"
import { Contact } from "./Contact"

export const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        @2021 <strong>Guiltfreejunk</strong> built with 💚 by Happiness Lab, Inc.{" "}
        <a href="/privacy">Privacy Policy</a>
      </p>
    </div>
    <div className="content has-text-centered">
      <div className="is-flex is-justify-content-center">
        <Contact />
      </div>
    </div>
  </footer>
)
