import React from "react"
import firebase from "firebase"
import Apple from "./assets/Apple-black.svg"

export const ConvertionButton = () => {
  const handleClick = () => {
    try {
      firebase.analytics().logEvent("press_apple_button")
      if (fbq) {
        fbq("track", "Lead")
      }
    } catch (err) {
    }
    window.location.href = "https://apps.apple.com/us/app/guiltfreejunk/id1537748651"
  }

  return (
    <div className="is-clickable" onClick={handleClick}>
      <img src={Apple} height={70} width={200} alt="AppleStore icon" />
    </div>
  )
}
