import React from "react"
import iPhoneImage from "./assets/iPhone.png"
import { ConvertionButton } from './ConversionButton'
 
export function Hero() {
  return (
    <>
      <section className="hero is-info is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds is-flex is-flex-direction-column is-justify-content-center">
                <div className="is-justify-content-center">
                  <h1 className="title is-2">Guiltfreejunk</h1>
                  <h2 className="subtitle is-3">Stressless weight management</h2>
                  <ConvertionButton/>
                </div>
              </div>
              <div className="column is-one-third is-flex is-flex-direction-column is-justify-content-center">
                <div>
                  <img src={iPhoneImage} alt="iPhone" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
