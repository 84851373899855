import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faCalendar } from "@fortawesome/pro-light-svg-icons"
import { faTelegramPlane, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons"

export const Contact: React.FC = () => (
  <div className="buttons has-addons is-rounded is-primary">
    <a className="button is-primary" href="mailto:antono@guiltfreejunk.com">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faEnvelope} size="1x" />
      </span>
      <span>Contact</span>
    </a>
    <a href="https://calendly.com/guiltfreejunk/help-anton-figure-out-guiltfreejunk-app" className="button is-outlined is-primary">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faCalendar} size="1x" />
      </span>
    </a>
    <a href="https://t.me/mavarazy" className="button is-outlined is-primary">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faTelegramPlane} size="1x" />
      </span>
    </a>
    <a href="https://wa.link/awqlfk" className="button is-outlined is-primary">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faWhatsapp} size="1x" />
      </span>
    </a>
    <a href="https://www.youtube.com/channel/UCZBBuRR8j6bMPMBrsbxeJAw" className="button is-outlined is-primary">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faYoutube} size="1x" />
      </span>
    </a>
  </div>
)
