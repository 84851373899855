import React from "react"
import "./App.scss"
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app"
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics"
import CookieConsent from "react-cookie-consent"
import { Hero } from "./Hero"
import { Footer } from "./Footer"
import { HelpMakeItBetter } from './HelpMakeItBetter'

const firebaseConfig = {
  apiKey: "AIzaSyCqwGOsSOvamd5YXvIxqxLDIGNJ8QnC0ys",
  authDomain: "guiltfreejunk.firebaseapp.com",
  databaseURL: "https://guiltfreejunk.firebaseio.com",
  projectId: "guiltfreejunk",
  storageBucket: "guiltfreejunk.appspot.com",
  messagingSenderId: "220505588473",
  appId: "1:220505588473:web:e5c27e562d0caf720ed828",
  measurementId: "G-ZXE3752J1G",
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

function App() {
  return (
    <>
      <Hero />
      <HelpMakeItBetter />
      <Footer />
      <CookieConsent>
        We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners.
      </CookieConsent>
    </>
  )
}

export default App
