import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from "react"
import { faCalendar } from "@fortawesome/pro-light-svg-icons"

export const HelpMakeItBetter = () => (
  <>
    <section className="hero is-medium">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-2">We want to hear you</h1>
          <a className="button is-large" href="https://calendly.com/guiltfreejunk/help-anton-figure-out-guiltfreejunk-app?month=2021-04">
            <span className="icon">
              <FontAwesomeIcon icon={faCalendar} size="1x" />
            </span>
            <span>
              Talk to us
            </span>
          </a>
        </div>
      </div>
    </section>
  </>
)
